//sqlitecloud hooks
import { useEditData } from "@custom-hooks/useApi";
//sqlitecloud components
import ModalContent from "@generic-components/ModalContent";
//env variables
const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

function DeleteProject({ project, setDisableRowDuringAction, callback }) {
  const { env } = project;
  //get method to call editing api
  const editDataOpt = {
    errorCallback: () => {
      if (setDisableRowDuringAction) {
        setDisableRowDuringAction(false);
      }
      callback();
    },
    mutatedCallback: () => {
      if (setDisableRowDuringAction) {
        setDisableRowDuringAction(false);
      }
      callback();
    },
  };
  const { loading, mutatingData, editedData, error, editData } =
    useEditData(editDataOpt);
  //method call to remove node
  const remove = async (e) => {
    e.preventDefault();
    if (UPGRADING_INFRA && UPGRADING_INFRA.toLowerCase() === "true") {
      alert(
        "We're upgrading our infrastructure to serve you better! Deleting a project is temporarily disabled."
      );
    } else {
      if (setDisableRowDuringAction) {
        setDisableRowDuringAction(true);
      }
      const opt = {
        method: "GET",
        endpoint: `/api/projects/${project.id}/delete`,
        endpointCallLocation: "DeleteProject.js",
        mutateApis: [
          ["/api/projects", "useGetUserProjects"],
          ["/api/projects", "useGetProjects"],
        ],
      };
      await editData(opt);
    }
  };
  //render
  return (
    <ModalContent
      actionType={env !== 0 ? "" : "strong-confirm"}
      title={`Delete project?`}
      description={
        env !== 0
          ? [
              "Please note that before deleting a project, its environment must be set to <span class='tw-font-medium'>Development</span>.",
              "",
              "You can do this by navigating to <span class='tw-font-medium'>Edit Details</span> and updating the environment to <span class='tw-font-medium'>Development</span>.",
              "",
              "Once the environment is updated, you’ll be able to proceed with deleting the project.",
            ]
          : 0
      }
      showLoader={loading || mutatingData}
      disabled={loading || mutatingData || error || env !== 0}
      noCallback={callback}
      yesCallback={remove}
      resourceType="project"
      confirmString={project.name}
      verifyString="delete my project"
    />
  );
}

export default DeleteProject;
